<template>
	<div class="page">
		<el-form class="form_ordinary" label-width="100px">

			<div class="big_tit">APP用户</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="用户电话" style="width:33%">
					<el-input class="el_inner_width" v-model="app_user.tel" clearable>
						<el-button type="success" slot="append" @click="app_user_ser">查找</el-button>
					</el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="用户信息" style="width:33%">
					<el-input class="el_inner_width" v-model="app_user.info_text" disabled></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="司机状态" style="width:33%">
					<el-input class="el_inner_width" v-model="driver_info.status_text" disabled></el-input>
				</el-form-item>
			</div>


			<div class="big_tit">司机信息</div>
			<div class="tab1_inner">
				<div class="el_form_item" label="" style="width:49%">
					<div class="img_btn" @click="choose_img('dl_img')">
						<div v-if="!dl_img.src" class="btn">未上传</div>
						<img  v-else :src="dl_img.src" class="img">
					</div>
					<div class="bottom_text">驾驶证</div>
				</div>
				<div class="el_form_item" style="width:49%;margin-left:1%">
					<div class="img_btn" @click="choose_img('cy_img')">
						<div v-if="!cy_img.src" class="btn">未上传</div>
						<img  v-else :src="cy_img.src" class="img">
					</div>
					<div class="bottom_text">从业资格证</div>
				</div>
			</div>
		</el-form>
		<div style="margin-left:20px;margin-top:40px">
			<el-button type="primary" @click="ser_para_clear()">清空</el-button>
		</div>
		<div class="bottom_btns" v-if="app_user.has_ser&&driver_info.status!=2">
			<el-button type="primary" @click="sub()">提交</el-button>
		</div>

	</div>
</template>
<script>
	export default {
		data() {
			return {

				//app用户
				app_user:{
					has_ser:false,//是否已经查找过
					tel:'',//用户手机
					user_num:'',//用户编号
					info_text:'',//用户信息
				},

				//司机信息
				driver_info:{
					driver_id:false,//司机id
					status:'',//审核状态
					status_text:''
				},

				//行驶证
				dl_img:{
					src:'',
					key:'',
					is_upl:false,
					is_choose:false,
					bucket:'driverdl'
				},

				//从业资格证
				cy_img:{
					src:'',
					key:'',
					is_upl:false,
					is_choose:false,
					bucket:'drivercy'
				},

				//提交锁
				sub_swh:false
			}
		},
		created(){
			
			//初始化
			this.init();
		},
		methods: {
			// 清空
			//执行提交
			do_sub(){

				//行驶证
				if(!this.dl_img.is_upl&&this.dl_img.is_choose)return;

				//从业资格证
				if(!this.cy_img.is_upl&&this.cy_img.is_choose)return;

				if(this.sub_swh){
					return;
				}

				//上锁
				this.sub_swh=true

				//表单
				let form={
					mod:'truck_driver',
					ctr:'driver_creat_by_admin',
					user_num:this.app_user.user_num,
					dl_img:this.dl_img.key,
					cy_img:this.cy_img.key,
				}

				//调接口
				this.$my.net.req({
					data:form,
					callback:(data)=>{
						
						//报告结果
						this.$my.other.msg({
							type:'success',
							str:'操作成功'
						});

						//表单初始化
						this.init();
					}
				});
			},

			//提交
			sub(){

				if(!(this.app_user.has_ser&&this.driver_info.status!=2)){
					return;
				}

				if(this.sub_swh){
					this.$my.other.msg({
						type:'warning',
						str:'提交中...'
					});
					return;
				}

				//询问
				this.$my.other.confirm({
					content:"点击'确定'添加此司机",
					confirm:()=>{

						//行驶证
						if(this.dl_img.is_choose){
							
							//上传图片
							this.$my.qiniu.upl({
								bucket: this.dl_img.bucket,
								key: this.dl_img.key, //xxxxxx?v=1 或者 null
								file_path: this.dl_img.src,
								callback: (data) => {
									this.dl_img.is_upl = true;
									this.dl_img.key = data.key;
									this.do_sub();
								}
							});
						}

						//从业资格证
						if(this.cy_img.is_choose){
							
							//上传图片
							this.$my.qiniu.upl({
								bucket: this.cy_img.bucket,
								key: this.cy_img.key, //xxxxxx?v=1 或者 null
								file_path: this.cy_img.src,
								callback: (data) => {
									this.cy_img.is_upl = true;
									this.cy_img.key = data.key;
									this.do_sub();
								}
							});
						}

						//用于补充
						this.do_sub();
					}
				});
			},

			//选择图片
			choose_img(img_name) {

				//未搜索不能修改
				if(!(this.app_user.has_ser&&this.driver_info.status!=2)){
					return;
				}

				//取出相应对象
				let ctr_obj = this[img_name]

				//选择图片
				this.$my.qiniu.choose_img({
					success: (res) => {
						ctr_obj.src = res.src;
						ctr_obj.is_choose = true;
						ctr_obj.is_upl = false;
					}
				});
			},

			//司机查找
			driver_ser(){

				if(!this.app_user.user_num){
					this.$my.other.msg({
						type:'warning',
						str:'请先查询'
					});
					return;
				}

				//读取已添加的司机
				this.$my.net.req({
					data:{
						mod:'truck_driver',
						ctr:'driver_list_by_admin',
						user_num:this.app_user.user_num,
					},
					callback:(data)=>{

						//取出司机信息
						let driver_info=data.list[0]?data.list[0]:{};

						//司机id
						this.driver_info.driver_id=driver_info.id

						//司机状态
						this.driver_info.status=driver_info.status
						switch(driver_info.status){
							case '1':this.driver_info.status_text="审核中";break;
							case '2':this.driver_info.status_text="审核通过";break;
							case '3':this.driver_info.status_text="审核未通过";break;
							default :this.driver_info.status_text="未添加";break;
						}

						//驾驶证
						if(driver_info.dl_img){
							this.dl_img.key=driver_info.dl_img
							this.dl_img.src=this.$my.qiniu.make_src('driverdl',driver_info.dl_img)
						}else{
							this.dl_img.key=""
							this.dl_img.src=""
						}
						this.dl_img.is_choose=false
						this.dl_img.is_upl=false

						//从业资格证
						if(driver_info.cy_img){
							this.cy_img.key=driver_info.cy_img
							this.cy_img.src=this.$my.qiniu.make_src('drivercy',driver_info.cy_img)
						}else{
							this.cy_img.key=""
							this.cy_img.src=""
						}
						this.cy_img.is_choose=false
						this.cy_img.is_upl=false
					}
				});
			},

			//司机查找
			app_user_ser(){

				if(!this.$my.check.is_tel(this.app_user.tel)){
					this.$my.other.msg({
						type:'warning',
						str:'缺少司机电话'
					});
					return;
				}

				//读取司机信息
				this.$my.net.req({
					data:{
						mod:'app_user',
						ctr:'app_user_list_by_admin',
						tel:this.app_user.tel,
					},
					callback:(data)=>{

						//取出司机数据
						let user_info=data.list[0];

						//未注册
						if(!user_info){
							this.$my.other.msg({
								type:'warning',
								str:'此用户未注册, 请先注册'
							});
							return;
						}

						//已搜索
						this.app_user.has_ser=true;

						//司机编号
						this.app_user.user_num=user_info.user_num

						//实名状态字符串
						switch(user_info.real_status){
							case '1':user_info.real_status_text="未实名";break;
							case '2':user_info.real_status_text="已实名";break;
							case '3':user_info.real_status_text="实名失败";break;
						}

						//司机信息字符串
						this.app_user.info_text=user_info.name+" / "+user_info.real_status_text
						
						//读取此人司机信息
						this.driver_ser()
					}
				});
			},

			//初始化
			init(){

				//app用户
				this.app_user.has_ser=false;
				this.app_user.tel=""
				this.app_user.user_num=""
				this.app_user.info_text=""

				//司机信息
				this.driver_info.driver_id=""
				this.driver_info.status=""
				this.driver_info.status_text=""

				//行驶证
				this.dl_img.src=""
				this.dl_img.key=""
				this.dl_img.is_upl=false
				this.dl_img.is_choose=false

				//从业资格证
				this.cy_img.src=""
				this.cy_img.key=""
				this.cy_img.is_upl=false
				this.cy_img.is_choose=false
				
				//解除提交锁
				this.sub_swh=false
			},
		}
	}
</script>
<style lang="scss" scoped>

	.el_form_item{
		margin: 0;
		.img_btn{
			border: 1px solid #bbb;
			border-radius: 5px;
			cursor: pointer;
			padding: 5px;
			height:300px;
			.btn{
				text-align: center;
				line-height: 290px;
				font-size: 14px;
			}

			.img{
				display: block;
				margin:auto;
				max-width: 100%;
				max-height: 290px
			}
		}
		.bottom_text{
			margin-top: 5px;
			text-align: center;
		}
	}
	.big_tit{
		background-color: #eee;
		padding: 10px 20px;
		color: #666;
		font-weight: 600;
		font-size: 18px;
		margin-top: 20px;
	}
	.tab1_inner{
		padding: 15px 15px 0 15px;
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
	}
	.el_inner_width{
		width:94%;
	}
	
	.bottom_btns{
		text-align: center;
		margin-top: 30px;
	}

	.page{
		background-color: #fff;
		padding:0 20px 20px 20px;
		border-radius: 10px;
		height: calc(100% - 20px);
		width: 1040px;
		overflow: auto;
		margin: auto;
	}
		
</style>